import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import gridSVG from '../../../Image/grid.svg';
import receptionLogo from '../../../Image/icon_reception.svg';
import schedulingLogo from '../../../Image/icon_scheduling.svg';
import roomsLogo from '../../../Image/icon_rooms.svg';
import otherProductsLogo from '../../../Image/other_products.svg';
import AppLink from './AppLink';

const activeGreen =
  'invert(8%) sepia(16%) saturate(123%) hue-rotate(101deg) brightness(104%) contrast(91%)';

const GridIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  margin-top: 8px;
  cursor: pointer;
  filter: ${props => (props.toggled ? activeGreen : '')};
  &:hover {
    filter: ${activeGreen};
  }
`;

const OwnedProduct = styled.div`
  margin-bottom: 18px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e7e9ea;
`;

const OtherProduct = styled.div``;

const DropDownSectionTitle = styled.span`
  margin-top: 20px;
  margin-left: 22px;
  font-size: 11px;
  font-weight: 500;
  line-height: 2.49;
  text-align: left;
  color: #68878d;
`;

const DropDownContent = styled.div`
  position: absolute;
  width: 347px;
  z-index: 10;
  border-radius: 10px;
  padding-top: 18px;
  box-shadow: 0 10px 26px 0 rgba(63, 78, 90, 0.16);
  background-color: #fff;
`;

function addIconsToProducts(owned, other) {
  const iconMap = {
    reception: receptionLogo,
    meetingroom: roomsLogo,
    scheduling: schedulingLogo,
    other: otherProductsLogo,
  };
  function addIconToProduct(obj) {
    const icon = iconMap[obj.product_type];
    return { ...obj, icon };
  }
  const ownedWithIcons = owned.map(addIconToProduct);
  const otherWithIcons = other.map(addIconToProduct);
  return { updatedOwned: ownedWithIcons, updatedOther: otherWithIcons };
}

function ProductDropDown({
  owned,
  other,
  ownedLabel,
  otherLabel,
  freeLabel,
  currentApp,
}) {
  const [toggled, setToggled] = useState(false);
  const productDropDownRef = useRef(null);
  const { updatedOwned, updatedOther } = addIconsToProducts(owned, other);

  const handleClickOutside = event => {
    if (
      productDropDownRef.current &&
      !productDropDownRef.current.contains(event.target)
    ) {
      setToggled(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={productDropDownRef}>
      <GridIcon
        src={gridSVG}
        onClick={() => setToggled(!toggled)}
        toggled={toggled}
      />
      {toggled && (
        <DropDownContent>
          <DropDownSectionTitle>{ownedLabel}</DropDownSectionTitle>
          <OwnedProduct>
            {updatedOwned.map(product => (
              <AppLink
                key={product.name}
                link={product.link}
                product={product.product_type}
                icon={product.icon}
                productName={product.name}
                isActive
                freeLabel={freeLabel}
                description={product.description}
                currentApp={currentApp}
              />
            ))}
          </OwnedProduct>
          <DropDownSectionTitle>{otherLabel}</DropDownSectionTitle>
          <OtherProduct>
            {updatedOther.map(product => (
              <AppLink
                key={product.name}
                link={product.link}
                icon={product.icon}
                product={product.product_type}
                productName={product.name}
                isActive={product.isActive}
                freeLabel={freeLabel}
                description={product.description}
              />
            ))}
          </OtherProduct>
        </DropDownContent>
      )}
    </div>
  );
}

ProductDropDown.defaultProps = {
  owned: null,
  other: null,
  ownedLabel: null,
  otherLabel: null,
  currentApp: null,
  freeLabel: null,
};

ProductDropDown.propTypes = {
  owned: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  other: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  ownedLabel: PropTypes.string,
  otherLabel: PropTypes.string,
  currentApp: PropTypes.string,
  freeLabel: PropTypes.string,
};

export default ProductDropDown;
